function sendData(form,target) {

    const XHR = new XMLHttpRequest();

    const FD = new FormData(form)
    console.log("test title: "+FD.get('title'));

    XHR.addEventListener("load", (event) => {
      location.reload();
    });

    XHR.addEventListener("error", (event) => {
      alert("Une erreur est survenue.");
    });

    XHR.open("POST", target);
    XHR.send(FD);
}



function sendAjax(url){
  var xhr = new XMLHttpRequest();
  xhr.addEventListener("load", (event) => {
    location.reload();
  });
  xhr.addEventListener("error", (event) => {
    alert("Une erreur est survenue.");
  });
  xhr.open('POST', url);
  xhr.send()
}